.TransactionBuilder {
  padding-bottom: 4em;
}
.TransactionBuilder__clearBar {
  margin-bottom: 0.5em;
}
@include r-media(l) {
  .TransactionBuilder__clearBar {
    text-align: right;
  }
}

.TransactionBuilder__result {
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid $s-color-neutral7;
  border-bottom: 1px solid $s-color-neutral7;

  background: $s-color-neutral8;
}
.TransactionBuilder__result :last-child {
  margin-bottom: 0;
}

.TransactionAttributes {
  margin-bottom: 1.5em;
}

.TransactionOp {
  @include S-flex-row;
  margin-bottom: 1.5em;
}
.TransactionOp__meta {
  @include S-flexItem-noFlex;
}
.TransactionOp__config {
  @include S-flexItem-share;
}

.TransactionOpMeta {
  width: 108px;
  text-align: center;
}
.TransactionOpMeta__order {
  padding-bottom: 0.5em;
}
.TransactionOpMeta__order__input {
  height: 72px;
  width: 72px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-size: $s-scale-a;
}
.TransactionOpMeta__tally__button {
  text-align: center;
  width: 72px;
  padding: 0.35em 0;
  font-size: $s-scale-down1;
  margin: 0.25em 0;
}
.TransactionOperations__add {
  width: 100%;
  padding-left: 108px;
  margin-bottom: 1.5em;
}
.TransactionOperations__add__button {
  width: 100%;
  font-size: $s-scale-3;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
}

.TransactionBuilderResult__code {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  background: $s-color-neutral9;
  cursor: pointer;
  word-wrap: break-word;
}
.TransactionBuilderResult__success {
  color: $s-color-success;
}
.TransactionBuilderResult__instructions {
  margin-bottom: 1em;
}

.NumberFractionPicker__fraction {
  display: flex;
  align-items: center;
  gap: 0.5em;

  & > div {
    flex: 1;
  }
}

.LiquidityPoolAssetPicker {
  margin-top: 0.5em;
}

.LiquidityPoolAssetPicker__item {
  margin-bottom: 1em;
  padding-left: 1em;
}

.LiquidityPoolAssetPicker__label {
  margin-bottom: 0.25em;
}
